'use client'
import { Link } from '@/navigation'
import { usePathname } from '@/navigation'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'

export default function SCP_SiteLogo() {
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const __t = useTranslations()
  const locale = useLocale()
  let image = '/images/logo.svg'
  if (
    pathName.includes('/center-clinic/dental-care') ||
    searchParams.get('centerSlug') === 'bangkok-hospital-dental-center'
  ) {
    image =
      'https://static.bangkokhospital.com/uploads/2024/05/bhq-dental-center.svg'
  }
  return (
    <Link
      aria-label={__t('go-to-homepage')}
      prefetch={false}
      href="/"
      className="tw-px-4 md:ltr:tw-pl-2 md:rtl:tw-pr-2 md:ltr:tw-border-r md:rtl:tw-border-l tw-border-bgh-black-alpha"
    >
      <Image
        src={image}
        width={160}
        height={36}
        alt="logo"
        className="tw-w-48 max-sm:tw-max-w-[128px]"
      />
    </Link>
  )
}
