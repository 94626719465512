function stringAvatar(_name = 'BHQ') {
  let name = stripThaiSara(_name)?.split(' ')
  let firstName = name[0][0]
  // let lastName = name[1] ? name[1][0] : ''
  return {
    children: firstName ? (
      `${firstName}`.toLocaleUpperCase()
    ) : (
      <i aria-hidden="true" className="fas fa-user tw-text-base" />
    ),
  }
}

function stripThaiSara(str) {
  // replace `โ`, `เ`, `แ`, ไ, ใ with ''
  return str.replace(/[โ|เ|แ|ไ|ใ]/g, '')
}

export { stringAvatar }
